export const adminRole = "Admin";
export const userRole = "User";
export const InfinityTraderRole = "Infinity Trader";
export const Affiliate = "Affiliate";
export const Influencer = "Influencer";
export const schoolRole = "School";

export const paperTrader = "paperTrade";
export const infinityTrader = "Infinity Trader";
export const dailyContest = "Daily Contest";
export const marginX = "MarginX";
export const tenxTrader = "TenX Trader";
export const zerodhaAccountType = "ZERODHA";
export const xtsAccountType = "XTS";
export const paytmMoneyAccountType = "PAYTM MONEY";
export const growAccountType = "GROW";
export const internshipTrader = "Internship Trader";
export const battle = "Battle";


export const maxLot_BankNifty = 900;
export const maxLot_Nifty = 1800;
export const maxLot_FinNifty = 1800;
export const maxLot_Nifty_DailyContest = 1800;
export const lotSize_BankNifty = 15;
export const lotSize_Nifty = 25;
export const lotSize_FinNifty = 40;