/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDAvatar from "../../../../../components/MDAvatar";
import MDProgress from "../../../../../components/MDProgress";

// Images
import logoXD from "../../../../../assets/images/small-logos/logo-xd.svg";
import logoAtlassian from "../../../../../assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "../../../../../assets/images/small-logos/logo-slack.svg";
import logoSpotify from "../../../../../assets/images/small-logos/logo-spotify.svg";
import logoJira from "../../../../../assets/images/small-logos/logo-jira.svg";
import logoInvesion from "../../../../../assets/images/small-logos/logo-invision.svg";
import team1 from "../../../../../assets/images/team-1.jpg";
import team2 from "../../../../../assets/images/team-2.jpg";
import team3 from "../../../../../assets/images/team-3.jpg";
import team4 from "../../../../../assets/images/team-4.jpg";

export default function data() {
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Trader Name", accessor: "trader", width: "10%", align: "left" },
      { Header: "gross p&l", accessor: "tgpnl", width: "10%", align: "left" },
      { Header: "transaction cost", accessor: "tcost", width: "10%", align: "center" },
      { Header: "net p&l", accessor: "tnpnl", width: "10%", align: "center" },
      { Header: "# of traders", accessor: "trades", width: "10%", align: "center" },
      { Header: "avg. daily loss", accessor: "avgdl", width: "10%", align: "center" },
    ],

    rows: [
      {
        trader: <Company image={team2} name="Prateek Pawan" />,
        members: (
          <MDBox display="flex" py={1}>
            {avatars([
              [team1, "Ryan Tompson"],
              [team2, "Romina Hadid"],
              [team3, "Alexander Smith"],
              [team4, "Jessica Doe"],
            ])}
          </MDBox>
        ),
        tgpnl: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -₹1,70,000
          </MDTypography>
        ),
        // tcost: (
        //   <MDBox width="8rem" textAlign="left">
        //     <MDProgress value={60} color="info" variant="gradient" label={false} />
        //   </MDBox>
        // ),
        tcost: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            ₹1,00,000
          </MDTypography>
        ),
        tnpnl: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -₹1,00,000
          </MDTypography>
        ),
        tnpnl: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -₹1,00,000
          </MDTypography>
        ),
      },
      {
        trader: <Company image={team1} name="Anamika Verma" />,
        members: (
          <MDBox display="flex" py={1}>
            {avatars([
              [team2, "Romina Hadid"],
              [team4, "Jessica Doe"],
            ])}
          </MDBox>
        ),
        tgpnl: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -₹1,40,000
          </MDTypography>
        ),
        // tcost: (
        //   <MDBox width="8rem" textAlign="left">
        //     <MDProgress value={10} color="info" variant="gradient" label={false} />
        //   </MDBox>
        // ),
        tcost: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            ₹1,00,000
          </MDTypography>
        ),
        tnpnl: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -₹1,00,000
          </MDTypography>
        ),
      },
      {
        trader: <Company image={team4} name="Shrikesh Kumar" />,
        members: (
          <MDBox display="flex" py={1}>
            {avatars([
              [team1, "Ryan Tompson"],
              [team3, "Alexander Smith"],
            ])}
          </MDBox>
        ),
        tgpnl: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -₹1,20,000
          </MDTypography>
        ),
        // tcost: (
        //   <MDBox width="8rem" textAlign="left">
        //     <MDProgress value={100} color="success" variant="gradient" label={false} />
        //   </MDBox>
        // ),
        tcost: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            ₹1,00,000
          </MDTypography>
        ),
        tnpnl: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -₹1,00,000
          </MDTypography>
        ),
      },
      {
        trader: <Company image={team2} name="Manav Yadav" />,
        members: (
          <MDBox display="flex" py={1}>
            {avatars([
              [team4, "Jessica Doe"],
              [team3, "Alexander Smith"],
              [team2, "Romina Hadid"],
              [team1, "Ryan Tompson"],
            ])}
          </MDBox>
        ),
        tgpnl: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -₹1,00,000
          </MDTypography>
        ),
        // tcost: (
        //   <MDBox width="8rem" textAlign="left">
        //     <MDProgress value={100} color="success" variant="gradient" label={false} />
        //   </MDBox>
        // ),
        tcost: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            ₹1,00,000
          </MDTypography>
        ),
        tnpnl: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -₹1,00,000
          </MDTypography>
        ),
      },
      {
        trader: <Company image={team3} name="Monika Agarwal" />,
        members: (
          <MDBox display="flex" py={1}>
            {avatars([[team4, "Jessica Doe"]])}
          </MDBox>
        ),
        tgpnl: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -₹40,000
          </MDTypography>
        ),
        // tcost: (
        //   <MDBox width="8rem" textAlign="left">
        //     <MDProgress value={25} color="info" variant="gradient" label={false} />
        //   </MDBox>
        // ),
        tcost: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            ₹1,00,000
          </MDTypography>
        ),
        tnpnl: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -₹1,00,000
          </MDTypography>
        ),
      },
    ],
  };
}
