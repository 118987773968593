export default function TransactionData() {
 


  return {
    columns: [
      { Header: "rows", accessor: "rows", align: "center" },   
    ],

    rows: [
 
    ],
  };
}
