// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ReactGA from "react-ga";
import React, { useEffect, useContext, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DataTable from "../../examples/Tables/DataTable";
import Header from "./Header";
import { userContext } from "../../AuthContext";

// Data

function UserOrders() {
  let baseUrl =
    process.env.NODE_ENV === "production" ? "/" : "http://localhost:5000/";
  const getDetails = useContext(userContext);
  useEffect(() => {
    window.webengage.track("order_tab_clicked", {
      user: getDetails?.userDetails?._id,
    });
    ReactGA.pageview(window.location.pathname);
    capturePageView();
  }, []);
  let page = "Orders";
  let pageLink = window.location.pathname;
  async function capturePageView() {
    await fetch(`${baseUrl}api/v1/pageview/${page}${pageLink}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    });
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Header />
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default UserOrders;
