import { useState, useEffect, useContext } from "react";
import { userContext } from "../../../AuthContext";
import axios from "axios";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import IconEmail from "@mui/material/Icon";
import purse from "../../../assets/images/purse.png";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import accountIcon from "../../../assets/images/default-profile.png";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "../../Breadcrumbs";
import NotificationItem from "../../Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "./styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "../../../context";
import { Tooltip } from "@mui/material";
import MDTypography from "../../../components/MDTypography";

function DashboardNavbar({ absolute, light, isMini }) {
  const getDetails = useContext(userContext);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [myWallet, setMyWallet] = useState([]);
  let baseUrl =
    process.env.NODE_ENV === "production" ? "/" : "http://localhost:5000/";

  useEffect(() => {
    let call1 = axios.get(`${baseUrl}api/v1/userwallet/my`, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    });

    Promise.all([call1])
      .then(([api1Response]) => {
        setMyWallet(api1Response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    // function handleTransparentNavbar() {
    // setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    setTransparentNavbar(dispatch, false);

    // }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    // window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    // handleTransparentNavbar();

    // Remove event listener on cleanup
    // return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Messages" />
      {/* <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" /> */}
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Orders" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const cashTransactions = myWallet?.transactions?.filter((transaction) => {
    return transaction.transactionType === "Cash";
  });
  // console.log(myWallet?.transactions);

  const totalCashAmount = cashTransactions?.reduce((total, transaction) => {
    return total + transaction?.amount;
  }, 0);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={decodeURI(route[route.length - 1])}
            route={route}
            light={light}
          />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              <MDTypography fontSize={15}>
                Hello {getDetails?.userDetails?.first_name}!
              </MDTypography>
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              <Link to="/wallet">
                <Tooltip title="Wallet" placement="top">
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <AccountBalanceWalletIcon color="dark" />
                    <MDTypography fontSize={15}>
                      ₹
                      {new Intl.NumberFormat(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(totalCashAmount ? totalCashAmount : 0)}
                    </MDTypography>
                  </IconButton>
                </Tooltip>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>

              <Link to="/notifications">
                <Tooltip title="Notifications" placement="top">
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    variant="contained"
                    // onClick={handleOpenMenu}
                  >
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </IconButton>
                </Tooltip>
              </Link>
              {renderMenu()}
              <Link to="/profile">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <img
                    src={
                      getDetails.userDetails?.profilePhoto?.url
                        ? getDetails.userDetails?.profilePhoto?.url
                        : accountIcon
                    }
                    width={30}
                    height={30}
                    style={{ borderRadius: "50%" }}
                  />
                </IconButton>
              </Link>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
